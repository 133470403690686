import { MobileService } from './../../_services/mobile-service.service';
import { AvatarComponent } from './../../shared/avatar/avatar.component';
import { CropperDialogComponent } from './../../common/UIControls/cropper-dialog/cropper-dialog.component';
import { SharedService } from 'src/app/_services/shared.service';
import { UserService } from 'src/app/_services/user-service.service';
import { CameraDialogComponent } from './../../common/UIControls/camera-dialog/camera-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { StyleRenderer, lyl, WithStyles, ThemeRef, ThemeVariables } from '@alyle/ui';
import {
  ImgCropperConfig,
  ImgCropperEvent,
  LyImageCropper,
  ImgCropperErrorEvent,
  ImgCropperLoaderConfig,
  STYLES as CROPPER_STYLES
} from '@alyle/ui/image-cropper';
import { Platform } from '@angular/cdk/platform';
import { take } from 'rxjs/operators';
import { PostService } from 'src/app/_services/postService';
import { baseResult } from 'src/app/_models/baseResult';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from 'src/app/common/dialogs/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RsaService } from 'src/app/_services/Rsa.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SnackBarComponent } from 'src/app/common/snack-bar/snack-bar.component';



@Component({
  selector: 'app-user-image',
  templateUrl: './user-image.component.html',
  styleUrls: ['./user-image.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StyleRenderer
  ]
})
export class UserImageComponent implements WithStyles {

  croppedImage?: string | null = null;
  scale: number;
  ready = false;
  minScale: number;
  image: string = '';
  watingImage: string = '';
  currentUser = this.user.getUser();
  /* @ViewChild(LyImageCropper) readonly cropper!: LyImageCropper;
  myConfig: ImgCropperConfig = {
    // autoCrop: true,
    width: 256, // Default `250`
    height: 256, // Default `200`
    fill: '#ff2997', // Default transparent if type == png else #000
    type: 'image/png', // Or you can also use `image/jpeg`
    responsiveArea: true,
    output: {
      width: 256,
      height: 256
    }

  }; */
  imagePreview = true;
  diableDelete = false;
  @ViewChild(AvatarComponent, { static: false }) readonly avatar!: AvatarComponent;
  showDeleteButton = false;
  imageChanged = false;
  constructor(
    private translate: TranslateService,
    private mobileService: MobileService,
    readonly sRenderer: StyleRenderer,
    private _platform: Platform,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<UserImageComponent>,
    private postSrv: PostService,
    public shared: SharedService,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private user: UserService,
    private rsaService: RsaService,
    @Inject(MAT_DIALOG_DATA) public data: { image: string, watingImage: string, studentId: string, checkWatingImage: boolean, imageUserType?: number },
  ) {
    this.image = this.data.image;
    this.watingImage = this.data.watingImage + '&' + (new Date).getTime();
    if (data.checkWatingImage)
      this.checkWatingImage();
    else this.showDeleteButton = true;
  }



  checkWatingImage() {
    this.http.get(this.watingImage, { responseType: 'text' }).subscribe(result => {
      console.log(result);
      this.showDeleteButton = (result != 'FileNotFound');
    }, error => console.log(error));
  }

  handleFileUpload(files: FileList) {
    if (files.length == 0) return;
    this.imagePreview = true;
    const fileReader = new FileReader();
    // Set the onload callback to do something with the loaded file
    fileReader.onload = (e) => {
      // just log the data url
      const config: ImgCropperLoaderConfig = {
        scale: 0.57,
        xOrigin: 200,
        yOrigin: 200,
        rotation: 0,
        originalDataURL: fileReader.result.toString()
      };
      this.openCropper(config, false, this.avatar);

    };
    // Trigger the file load
    fileReader.readAsDataURL(files.item(0));
  }
  imageSelect(tmp) {
    this.handleFileUpload(tmp);
    return;
  }
  openCamera() {
    if (this.mobileService.isMobileView) {

      document.getElementById('picture').click();
      return;
    }

    this.imagePreview = true;

    const dialogRef = this.matDialog.open(CameraDialogComponent, {
      disableClose: true,
      autoFocus: true,
      width: '450px',
      height: '600px',
      maxWidth: '90vW'

    });
    dialogRef.afterClosed().pipe(take(1)).subscribe((result: any) => {
      if (result != false && result != undefined) {
        const config: ImgCropperLoaderConfig = {
          scale: 0.57,
          xOrigin: 200,
          yOrigin: 200,
          rotation: 0,
          originalDataURL: result
        };
        this.openCropper(config, false);
      } else {
        this.imagePreview = false;
      }
    });
  }

  uploadFile($event) {
    this.imagePreview = true;

    this.openCropper($event, true)

    // this.cropper.selectInputEvent($event)

  }


  openCropper(input, isUploadFile, avatar = null) {
    const dialogRef = this.matDialog.open(CropperDialogComponent, {
      disableClose: true,
      data: {
        input: input,
        isUploadFile: isUploadFile
      },
      width: '450px',
      maxWidth: '90vW',

      autoFocus: true
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe((img: any) => {

      this.imagePreview = false;
      if (img != false) {
        this.imagePreview = true;
        this.diableDelete = false;
        if (this.avatar == undefined) {
          avatar.waitingImageUrl = this.watingImage = this.croppedImage = img;
          avatar.update();
        } else {

          this.avatar.waitingImageUrl = this.watingImage = this.croppedImage = img;
          this.avatar.update();
        }
        this.saveCroppedImage();
      }
    });


  }
  changeView() {
    this.imagePreview = false;
  }

  saveCroppedImage() {
    this.postSrv.postDataImage<string>('api/stream/SaveUserImage',
      false,
      {
        'id': this.croppedImage,
        'param1': this.shared.isEmpty(this.data.studentId) ? '' : this.rsaService.encryptStringToServer(this.user.getUser().userId),
        'param2': this.shared.isEmpty(this.data.studentId) ? '' : this.rsaService.encryptStringToServer(this.data.studentId),
        'param3': this.data.imageUserType?.toString() ?? this.user.getUser().userType.toString()
      }).pipe(take(1)).subscribe((result: baseResult<string>) => {
        if (result || result.status) {
          this.imageChanged = true;
          this.shared.imgTs = (new Date()).getTime();
        }
      });
  }
  close() {
    this.dialogRef.close(this.imageChanged);
  }

  deleteImage() {
    let msg = `${this.translate.instant('ComfirmDeleteImage')}`;
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: { message: msg, confirmBtn: this.translate.instant('Delete'), cancelBtn: this.translate.instant('cancelBtn'), isHtml: true },
      autoFocus: true
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.postSrv.postDataImage<string>(
          'api/stream/deleteUserImage',
          true, {
          id: this.data.studentId,
          param1: this.data.checkWatingImage.toString(),
          param3: this.data.imageUserType?.toString() ?? this.user.getUser().userType.toString()

        }
        ).pipe(take(1)).subscribe((result: baseResult<string>) => {
          if (result.status) {
            this.shared.imgTs = (new Date()).getTime();

            this.imageChanged = true;

            if (result.data == '1') {
              msg = `${this.translate.instant('waitingimageDelete')}`;
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 3000,
                data: { message: msg, type: 'succcess' }
              });
              this.dialogRef.close(true);
            } else if (result.data == '2') {
              msg = `${this.translate.instant('waitingimageDelete')}`;
              this.image = '';
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 3000,
                data: { message: msg, type: 'succcess' }
              });
              this.dialogRef.close(true);
            } else if (result.data == '3') {
              msg = `${this.translate.instant('ImageDeleteFailed')}`;
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 3000,
                data: { message: msg, type: 'warning' }
              });
            }
          }
        });
      }
    });
  }


}
