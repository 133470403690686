import { MobileService } from 'src/app/_services/mobile-service.service';
import { Component, Inject, OnInit } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import {WebcamImage} from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
@Component({
  selector: 'app-camera-dialog',
  templateUrl: './camera-dialog.component.html',
  styleUrls: ['./camera-dialog.component.css']
})
export class CameraDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<CameraDialogComponent>,
    public mobileService: MobileService,
    @Inject(MAT_DIALOG_DATA) public data: { name: string, tz: string , className: string},

  ) {

  }

  ngOnInit(): void {
  }


  public webcamImage: WebcamImage;
  private trigger: Subject<void> = new Subject<void>();

  triggerSnapshot(): void {
   this.trigger.next();
   this.dialogRef.close(this.webcamImage.imageAsDataUrl)
  }

  handleImage(webcamImage: WebcamImage): void {
   console.info('Saved webcam image', webcamImage);
   this.webcamImage = webcamImage;
  }

  public get triggerObservable(): Observable<void> {
   return this.trigger.asObservable();
  }

  close() {
     this.dialogRef.close(false)

  }

}
