// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge {
  display: inline-block;
  padding: 4px 6px;
  font-size: var(--font-size--small) !important;
  line-height: 1;
  text-align: center;
  white-space: break-spaces;
  vertical-align: middle;
  border-radius: 2px;
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
  color: var(--white);
  font-family: var(--boldFont);
  font-weight: bold;
  font-weight: normal;
  border-color: transparent;
}

.badge.warning{
  color: var(--badge-warning-color);
  background-color: var(--badge-warning-background);
}
.margin0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.dragAndDrop{
  border-radius: 50%;
  padding: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/badges/warning-badge/warning-badge.component.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,6CAA6C;EAC7C,cAAc;EACd,kBAAkB;EAClB,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,yBAAwB;UAAxB,wBAAwB;EACxB,mBAAmB;EACnB,4BAA4B;EAC5B,iBAAiB;EACjB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,iCAAiC;EACjC,iDAAiD;AACnD;AACA;EACE,4BAA4B;EAC5B,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".badge {\r\n  display: inline-block;\r\n  padding: 4px 6px;\r\n  font-size: var(--font-size--small) !important;\r\n  line-height: 1;\r\n  text-align: center;\r\n  white-space: break-spaces;\r\n  vertical-align: middle;\r\n  border-radius: 2px;\r\n  margin-inline-start: 8px;\r\n  color: var(--white);\r\n  font-family: var(--boldFont);\r\n  font-weight: bold;\r\n  font-weight: normal;\r\n  border-color: transparent;\r\n}\r\n\r\n.badge.warning{\r\n  color: var(--badge-warning-color);\r\n  background-color: var(--badge-warning-background);\r\n}\r\n.margin0 {\r\n  margin-right: 0px !important;\r\n  margin-left: 0px !important;\r\n}\r\n\r\n.dragAndDrop{\r\n  border-radius: 50%;\r\n  padding: 0px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
