import { SharedService } from 'src/app/_services/shared.service';
import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output, ViewChild, AfterContentInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css', '../inputs.css']
})
export class MultiSelectComponent implements OnInit, OnChanges, AfterContentInit {

  @Input() public label: string;
  @Input() public options: any[];
  @Input() public value: string;
  @Input() public text: string;
  @Input() public compTabIndex = 0;
  @Input() public control: UntypedFormControl = new UntypedFormControl();
  @Input() public defaultValue: number | string | any;
  @Input() private selectAllOnStrater = false;
  @Input() public selectAllText: string;
  @Input() public disabled: boolean = false;
  @Input() public startSelected: string;
  @Input() public selectAllValue: number = 0;
  controlValue: number[];

  @Output() selectedValue: EventEmitter<string[] | number[]> = new EventEmitter<string[] | number[]>();
  @ViewChild('allSelected') private allSelected: MatOption;
  constructor(private fb: UntypedFormBuilder,
    public _shared: SharedService) { }

  ngAfterContentInit(): void {
    if (this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }
  setSelected(values) {
    this.control.setValue(values);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectAllOnStrater || this.startSelected != '') {
      this.ngOnInit();
      return;
    }
    if (this.control.value !== null && this.control.value !== undefined && this.control.value !== '') {
      this.defaultValue = this.control.value;
    }
    if (this.defaultValue !== undefined) {
      try {
        if ((this.defaultValue as []).length == 0) {
          this.control.setValue([]);
        }
      } catch (error) {
        if (Number(this.defaultValue) !== null && !isNaN(Number(this.defaultValue))) {
          this.control.setValue(Number(this.defaultValue));
        } else {
          this.control.setValue(this.defaultValue);
        }
      }
    }
  }

  ngOnInit() {
    if (this.control == null && this.options.length > 0) {
      this.control = new UntypedFormControl(this.options[0][this.value]);
    }
    if (this.selectAllOnStrater && this.options != null) {
      this.control.patchValue([...this.options.map(item => item[this.value])]);
      this.selectedValue.emit(this.control.value);
    }
    else if (this.startSelected != null && this.startSelected != '' && this.options != null) {
      this.control.patchValue([...this.startSelected.split(',').map(x=> parseInt(x))]);
      this.selectedValue.emit(this.control.value);
    }
    this.controlValue = this.control.value;
    this.addValueToBase();
  }

  tosslePerOne(all) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
    } else if (this.control.value.length === this.options.length) {
      this.allSelected.select();
    }
    this.selectedValue.emit(this.control.value);
    this.controlValue = this.control.value;
    this.addValueToBase();
  }

  addValueToBase() {
    if (this.options == null) return;
    if (this.control.value.filter(x => x !== 0).length === this.options.length) {
      if (this.controlValue[0] !== 0) {
        this.controlValue.splice(0, 0, 0);
      }
    }
  }

  itemClick(all) {
    const beforeValue = this.controlValue[0];
    const currentValue = (this.control.value as number[])[0];

    if (beforeValue !== currentValue && (beforeValue === 0 || currentValue === 0)) {
      this.toggleAllSelection();
    } else {
      this.tosslePerOne(all);
    }
  }

  getItemsLength() {
    try {
      return (this.control.value as []).filter(x => x != this.selectAllValue).length;

    } catch (error) {
      return 0;
    }
  }

  toggleAllSelection(flag = false) {
    if (this.allSelected.selected || flag) {
      this.control.patchValue([...this.options.map(item => item[this.value]), this.selectAllValue]);
    } else {
      this.control.patchValue([]);
    }
    this.selectedValue.emit(this.control.value);
    this.controlValue = this.control.value;
    this.addValueToBase();
  }





}
