// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge {
  display: inline-block;
  padding: 4px 6px;
  font-size: var(--font-size--small) !important;
  line-height: 1;
  text-align: center;
  white-space: break-spaces;
  vertical-align: middle;
  border-radius: 2px;
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
  color: var(--white);
  font-family: var(--boldFont);
  font-weight: bold;
  font-weight: normal;
  border-color: transparent;
}

.badge.info{
  background-color: var(--badge-info-background);
  color: var(--badge-info-color);
}

.checkboxBadge {
  border-radius: 50%;
  position: inherit;
  background: var(--alert-info-background);
  color:  var(--badge-info-color);
  line-height: 18px !important;
  padding: 0;
  height: 18px !important;
  width: 18px !important;
  font-size: var(--font-size--small);
  text-align: center;
  margin-right: 4px;
}

.infoGreen{
  background-color: green;
  color: var(--badge-info-color);
}

.badge.info.purple{
  background-color: var(--badge-info-2-background);
  color: var( --badge-info-2-color);
}

.margin0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/badges/info-badge/info-badge.component.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,6CAA6C;EAC7C,cAAc;EACd,kBAAkB;EAClB,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,yBAAwB;UAAxB,wBAAwB;EACxB,mBAAmB;EACnB,4BAA4B;EAC5B,iBAAiB;EACjB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,8CAA8C;EAC9C,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,wCAAwC;EACxC,+BAA+B;EAC/B,4BAA4B;EAC5B,UAAU;EACV,uBAAuB;EACvB,sBAAsB;EACtB,kCAAkC;EAClC,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,8BAA8B;AAChC;;AAEA;EACE,gDAAgD;EAChD,iCAAiC;AACnC;;AAEA;EACE,4BAA4B;EAC5B,2BAA2B;AAC7B","sourcesContent":[".badge {\r\n  display: inline-block;\r\n  padding: 4px 6px;\r\n  font-size: var(--font-size--small) !important;\r\n  line-height: 1;\r\n  text-align: center;\r\n  white-space: break-spaces;\r\n  vertical-align: middle;\r\n  border-radius: 2px;\r\n  margin-inline-start: 8px;\r\n  color: var(--white);\r\n  font-family: var(--boldFont);\r\n  font-weight: bold;\r\n  font-weight: normal;\r\n  border-color: transparent;\r\n}\r\n\r\n.badge.info{\r\n  background-color: var(--badge-info-background);\r\n  color: var(--badge-info-color);\r\n}\r\n\r\n.checkboxBadge {\r\n  border-radius: 50%;\r\n  position: inherit;\r\n  background: var(--alert-info-background);\r\n  color:  var(--badge-info-color);\r\n  line-height: 18px !important;\r\n  padding: 0;\r\n  height: 18px !important;\r\n  width: 18px !important;\r\n  font-size: var(--font-size--small);\r\n  text-align: center;\r\n  margin-right: 4px;\r\n}\r\n\r\n.infoGreen{\r\n  background-color: green;\r\n  color: var(--badge-info-color);\r\n}\r\n\r\n.badge.info.purple{\r\n  background-color: var(--badge-info-2-background);\r\n  color: var( --badge-info-2-color);\r\n}\r\n\r\n.margin0 {\r\n  margin-right: 0px !important;\r\n  margin-left: 0px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
