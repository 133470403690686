import { ListTableComponent } from './common/UIControls/table/list-table/list-table.component';
import { AlertTransparentComponent } from './common/alerts/alert-transparent/alert-transparent.component';
import { ClickableTooltipDirective } from './_directives/clickableTooltip-Directive';
import { InlineTextComponent } from './common/UIControls/inline-text/inline-text.component';
import { AvatarModule } from './shared/avatar/avatar.module';
import { DrawerModule } from './common/UIControls/drawer/drawer.module';
import { DoughtChartComponent } from './common/charts/dought-chart/dought-chart.component';
import { TableRowExpandComponent } from './common/table-row-expand/table-row-expand.component';
import { StepperComponent } from './common/UIControls/stepper/stepper.component';
import { SwipeItemComponent } from './common/UIControls/swipe-item/swipe-item.component';
import { CalenderPickerComponent } from './common/UIControls/calender-picker/calender-picker.component';
import { SwitchComponent } from './common/UIControls/switch/switch.component';
import { ColorDataComponent } from './common/UIControls/color-data/color-data.component';
import { PipesModule } from './modules/pipes.module';
import { UIModule } from './modules/UI.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MultiSelectOptgroupComponent } from './common/UIControls/multi-select-optgroup/multi-select-optgroup.component';
import { SearchComponent } from './common/UIControls/search/search.component';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSearchComponent } from './common/UIControls/inline-search/inline-search.component';
import { ColorInputComponent } from './common/UIControls/color-input/color-input.component';
import { AccordionComponent } from './common/UIControls/accordion/accordion.component';
import { ExpansionPanelComponent } from './common/UIControls/accordion/expansion-panel/expansion-panel.component';
import { AlertSucecessComponent } from './common/alerts/alert-sucecess/alert-sucecess.component';
import { AlertCommentComponent } from './common/alerts/alert-comment/alert-comment.component';
import { DatepickerHeader } from './common/UIControls/calender-picker/datepicker-header/datepicker-header.component';
import { ChipListComponent } from './common/UIControls/chip-list/chip-list.component';
import { NgModule } from '@angular/core';
import * as Materialmodule from './modules/material-module';
import MaterialModule = Materialmodule.MaterialModule;
import { CommonModule } from '@angular/common';
import { AlertInfoComponent } from './common/alerts/alert-info/alert-info.component';
import { AlertWarningComponent } from './common/alerts/alert-warning/alert-warning.component';

import { FormDialogComponent } from './common/dialogs/form-dialog/form-dialog.component';
import { DetailsDialogComponent } from './common/dialogs/details-dialog/details-dialog.component';

import { TextareaComponent } from './common/UIControls/textarea/textarea.component';
import { TextareWordcounterComponent } from './common/UIControls/textare-wordcounter/textare-wordcounter.component';
import {  MatCheckboxModule } from '@angular/material/checkbox';
import {  MatSelectModule,  MAT_SELECT_CONFIG } from '@angular/material/select';
import {  MatAutocompleteModule } from '@angular/material/autocomplete';
import { ConfirmDialogComponent } from './common/dialogs/confirm-dialog/confirm-dialog.component';
import {  ScrollingModule } from '@angular/cdk/scrolling';
import { InlineSelectComponent } from './common/UIControls/inline-select/inline-select.component';
import { InlineTextareaComponent } from './common/UIControls/inline-textarea/inline-textarea.component';
import { DatePickerComponent } from './common/dialogs/date-picker/date-picker.component';
import { TextEditComponent } from './common/dialogs/text-edit/text-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SorterDialogComponent } from './common/dialogs/sorter-dialog/sorter-dialog.component';
import { UserDataDialogComponent } from './common/dialogs/user-data-dialog/user-data-dialog.component';
import { TextEditorComponent } from './common/UIControls/text-editor/text-editor.component';
import { SelectOptgroupComponent } from './common/UIControls/select-optgroup/select-optgroup.component';
import { FileUploaderComponent } from './common/UIControls/file-uploader/file-uploader.component';
import { AttachedFileComponent } from './common/UIControls/attached-file/attached-file.component';
import { MessageBoxDialogComponent } from './common/dialogs/message-box-dialog/message-box-dialog.component';
import { MultiSelectComponent } from './common/UIControls/multi-select/multi-select.component';
import { ImageDialogComponent } from './common/dialogs/image-dialog/image-dialog.component';
import { PagerDialogComponent } from './common/dialogs/pager-dialog/pager-dialog.component';
import { RouterModule } from '@angular/router';
import { SchedualeViewComponent } from './common/scheduale-view/scheduale-view.component';
import { LayoutsModule } from './modules/layouts.module';
import { QrDialogComponent } from './common/dialogs/qr-dialog/qr-dialog.component';
import { QrCodeModule } from 'ng-qrcode';
import { TableComponent } from './common/UIControls/table/table/table.component';
import {  MatChipsModule } from '@angular/material/chips';
import { AutoCompleteComponent } from './common/UIControls/auto-complete/auto-complete.component';
import { AssessmentBankComponent } from './common/UIControls/assessment-bank/assessment-bank.component';
import { FullBankComponent } from './common/UIControls/assessment-bank/full-bank/full-bank.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ChartModule } from './common/charts/chart.module';
import {LongPressDirective} from '../app/_directives/long-press-directive.directive'
import {SwipeDirectiveDirective} from './_directives/swipe-directive.directive'
import {SnackBarComponent} from './common/snack-bar/snack-bar.component'
import { StudentBehaviorGradeComponent } from './my-pupils/my-pupil-data/student-control-panel/student-behavior-grade/student-behavior-grade.component';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
//import { FlexLayoutModule } from '@angular/flex-layout';

//#endregion

@NgModule({
  declarations: [

    /*Components */


    /*Controls */
    MultiSelectOptgroupComponent,
    MultiSelectComponent,
    ColorInputComponent,
    SwitchComponent,
    SearchComponent,
    ColorDataComponent,
    DatepickerHeader,
    TextareaComponent,
    AccordionComponent,
    TableRowExpandComponent,
    ExpansionPanelComponent,
    TextareWordcounterComponent,

    CalenderPickerComponent,
    ChipListComponent,
    AutoCompleteComponent,
    DatePickerComponent,
    TextEditComponent,
    TextEditorComponent,
    FileUploaderComponent,
    AttachedFileComponent,
    SelectOptgroupComponent,
    MultiSelectComponent,
    StepperComponent,
    SwipeItemComponent,
    /*Dialogs */
    FormDialogComponent,
    DetailsDialogComponent,
    ConfirmDialogComponent,
    SorterDialogComponent,
    UserDataDialogComponent,
    /*Alerts */
    AlertInfoComponent,
    AlertWarningComponent,
    AlertCommentComponent,
    AlertTransparentComponent,
    AlertSucecessComponent,
    MessageBoxDialogComponent,
    SearchComponent,
    ImageDialogComponent,
    PagerDialogComponent,
    SchedualeViewComponent,

    QrDialogComponent,
    TableComponent,
    ListTableComponent,
    AssessmentBankComponent,
    FullBankComponent,
    LongPressDirective,
    SwipeDirectiveDirective,
    SnackBarComponent,
    StudentBehaviorGradeComponent
  ], imports: [
    RouterModule,
    /* generic modules  */
    UIModule,
    PipesModule,
    LayoutsModule,
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    EditorModule,
    MaterialModule,
   // FlexLayoutModule,
    ScrollingModule ,
    LazyLoadImageModule,
    QrCodeModule,
    DrawerModule,
    AvatarModule,
    MatChipsModule,
    ChartModule,
    ClickableTooltipDirective,
    VirtualScrollerModule
  ],
  exports: [
    AvatarModule,
    LayoutsModule,
    UIModule,
    PipesModule,
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    EditorModule,
    MaterialModule,
  //  FlexLayoutModule,
    MatSelectModule,
    MatAutocompleteModule,
    ScrollingModule ,
    AlertInfoComponent,
    AlertWarningComponent,
    AlertCommentComponent,
    AlertTransparentComponent,
    AlertSucecessComponent,
    FormDialogComponent,
    DetailsDialogComponent,
    ColorInputComponent,
    SwitchComponent,
    ColorDataComponent,
    DatepickerHeader,
    TextareaComponent,
    AccordionComponent,
    TableRowExpandComponent,
    ExpansionPanelComponent,
    TextareWordcounterComponent,
    InlineSelectComponent,
    InlineTextareaComponent,
    InlineTextComponent,
    InlineSearchComponent,
    ConfirmDialogComponent,
    DatePickerComponent,
    ChipListComponent,
    AutoCompleteComponent,
    TextEditComponent,
    TextEditorComponent,
    SelectOptgroupComponent,
    FileUploaderComponent,
    AttachedFileComponent,
    SearchComponent,
    SorterDialogComponent,
    MultiSelectComponent,
    LazyLoadImageModule,
    MultiSelectOptgroupComponent,
    MultiSelectComponent,
    StepperComponent,
    SwipeItemComponent,
    PagerDialogComponent,
    CalenderPickerComponent,
    SchedualeViewComponent,
    QrCodeModule,
    UserDataDialogComponent,
    TableComponent,
    ListTableComponent,
    AssessmentBankComponent,
    FullBankComponent,
    LongPressDirective,
    SwipeDirectiveDirective,
    SnackBarComponent,
    ClickableTooltipDirective,
    StudentBehaviorGradeComponent
  ],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'select-overlay-pane' }
    }
  ]
})
export class SharedModule { }
