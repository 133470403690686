import { BiomericService } from './biometricService';
import { LogService } from './logService';

import { AppNavComponent } from './../app-nav/app-nav.component';
import { SharedService } from './shared.service';
import { Injectable } from '@angular/core';
import { UserService } from './user-service.service';
import { Router } from '@angular/router';
import { RsaService } from './Rsa.service';
import { CookiesService } from './cookies-service.service';
import { Location } from '@angular/common';
import { PupilCardSharedService } from './PupilCardSharedService';

@Injectable({
  providedIn: 'root'
})
export class PushService {
  paramArr: { [key: string]: string } = {};

  constructor(
    private shared: SharedService,
    private router: Router,
    private _location: Location,
    private user: UserService,
    private rsaService: RsaService,
    private nav: AppNavComponent,
    private cookiesService: CookiesService,
    private pupilCardService: PupilCardSharedService,
    private logService: LogService,
    private biomericService:BiomericService
  ) {

  }


  redirectPyParams(url: any, loginAfterBio = false, allParams = null) {
    let dec = this.rsaService.decrypt((url))
    if (dec == '/error' || this.shared.isEmpty(dec)) {
      this.router.navigate(['dashboard']);
    } else {
      if (loginAfterBio) {
        try {
          this.paramArr = JSON.parse(url);
          dec = encodeURI(this.paramArr['stateUrl']);
        } catch { }
      }

      if (allParams != null) {
        for (let key in allParams) {
          if (this.shared.isEmpty(this.paramArr[key])) {
            this.paramArr[key] = allParams[key];
          }
        }
      } else {
        allParams = this.shared.getAllParamsFromQuery();
        if (allParams != null) {
          for (let key in allParams) {
            if (this.shared.isEmpty(this.paramArr[key])) {
              this.paramArr[key] = allParams[key];
            }
          }
        }
      }

      let urltoToute = decodeURI(dec).toString().split('?')[0];
      let userType = this.shared.getParamValueFromQuery('userType')
      let institutionCode = this.shared.getParamValueFromQuery('institutionCode')
      if (loginAfterBio) {
        userType = this.paramArr['userType'];
        institutionCode = this.paramArr['institutionCode'];
      }

      let timeout = 1;
      if (this.user.getUser() != null && (!this.shared.isEmpty(userType) && !this.shared.isEmpty(institutionCode) && (userType != this.user.getUser().isTeacher || institutionCode != this.user.getUser().institutionCode))) {
        this.nav.usertypeChange('', userType, institutionCode);
        timeout = 500;
      }

      setTimeout(() => {
        let params = dec?.toString().split('?')[1]?.split('&');
        params?.forEach(p => {
          let id = p.split('=')[0];
          let val = p.split('=')[1];
          if (p.split('=').length == 3)
            val += '=';
          this.paramArr[id] = val;
        });
        //this.logService.writeToLog(JSON.stringify(this.paramArr));
        if (Object.keys(this.paramArr).length == 0 && !this.shared.isEmpty(this.shared.getParamValueFromQuery('webtopid'))) {
          this.paramArr['id'] = this.shared.getParamValueFromQuery('webtopid');
        }
        if (urltoToute.includes('Student_Card')) {
          let notificationDate: any = {};
          if (loginAfterBio)
            notificationDate.evaluationEventID = this.paramArr['notificationItem']
          else
            notificationDate.evaluationEventID = this.shared.getParamValueFromQuery('notificationItem');
          if (!this.shared.isEmpty(this.paramArr['student'])) {
            notificationDate.studentID = decodeURIComponent(this.paramArr['student'].replaceAll(' ', '+'));
          } else {
            if (this.user.getUser().userType == 0) {
              notificationDate.studentID = this.user.getUser().userId;
            }
          }

          this.pupilCardService.updateNotificationData(notificationDate)
        }
        if (this.user.getUser() != null) {

          this.user.getBioLogin().subscribe(result => {
            var bioLogin = result;
            if (!this.shared.isEmpty(bioLogin) && !loginAfterBio) {
              this.cookiesService.setItem('biometricUrl', JSON.stringify(this.paramArr));
              bioLogin = result['value'];
              if (this.checkLastPost())
                window.location.href = window.location.href.replace('push', 'account/login');
              else
                this.router.navigate([urltoToute], { queryParams: { ...this.paramArr } });
            } else {
              this.cookiesService.removeItem('biometricUrl');
              this.router.navigate([urltoToute], { queryParams: { ...this.paramArr } });
            }
          });
        } else {
          params = location.search.substring(1).split('&');
          this.paramArr = {};
          params?.forEach(p => {
            let id = p.split('=')[0];
            let val = p.split('=')[1];
            if (p.split('=').length == 3)
              val += '=';
            this.paramArr[id] = decodeURI(val);
          });
          this.user.getBioLogin().subscribe(result => {
            var bioLogin = result;
            if (!this.shared.isEmpty(bioLogin)) {
              this.cookiesService.setItem('biometricUrl', JSON.stringify(this.paramArr));
              bioLogin = result['value'];
              this.biomericService.biometricFingerprintPrompt(bioLogin);
            }
            window.location.href = window.location.href.replace('push', 'account/login');
          });
        }
      }, timeout);
    }
  }

  checkLastPost() {

    let now = new Date();
    let lastPost = localStorage.getItem('lastPost');
    if (this.shared.isEmpty(lastPost)) return true;
    let diff = (now.getTime() - Number(localStorage.getItem('lastPost'))) / 1000;
    return diff > 900;

  }

/*   biometricFingerprintPrompt(token) {

    try {
      //@ts-ignore
      webkit.messageHandlers.MobileDevice.postMessage({ action: 'biometricFingerprintPrompt', data: token });
    } catch (err) {
      //@ts-ignore
      MobileDevice.biometricFingerprintPrompt(token);
    }

  } */


}
