export const environment = {
  production: false,
  apiUrl: 'https://localhost:44389',
  reportUrl: 'https://localhost:5001/',
  /* apiUrl: 'https://testserver.smartschool.co.il', */
  loginMinstry: 'https://www.webtop.co.il/applications/loginMOENew/default.aspx',
  ClientCaptchaKey: '6Lc3FToeAAAAAEx1PNfEgSvIfrH1jl20NcigjolX',
  /* ClientCaptchaKeyInvisible: '6LdFnyAqAAAAANKCeiZMBYFeN7SUjJkb-LqtQwdh', */
  ClientCaptchaKeyInvisible: '6Lf-Bz4qAAAAAClftyz9ZpD7TJ93bQ15wpoiuLLJ',
  currentYear: 2025,
  defaultLanguage: 'he',
  viewSmartModulesFromYear: 2009,
  numBackYears: 10,
  studtentImage: '*',
  timeStamp: '1670936612555',
  AllowedFileTypes: "gif,png,jpg,jpeg,bmp,tif,tiff,mp3,wma,wav,ogg,flac,wmv,mswmm,avi,mp4,mpg,mkv,mov,flv,swf,pdf,doc,docx,dot,dotx,ppt,pptx,xls,accdb,xlsx,pps,ppsx,rtf,txt,flipchart,ink,notebook,pub,xps,ggb,accdb,csv,sketchup,sb,dwg,pkt,m4a"
};

