import { baseResult } from '../_models/baseResult';
//import { PostService } from './postService';
import { CookiesService } from './cookies-service.service';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http';
import { map, catchError, take } from 'rxjs/operators';
import { Observable } from 'rxjs-compat';
import { RsaService } from './Rsa.service';
import { UserService } from './user-service.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(
    private cookieService: CookiesService,
    private user: UserService,
    private rsa: RsaService,
    private http: HttpClient,

    //  private postSrv: PostService
  ) {

    this.getUrl();
  }

  apiUrl = '';

  getUrl() {

    switch (window.location.host.toLowerCase()) {
      case 'localhost:4200':
        this.apiUrl = 'https://localhost:44389';

        break;
      case 'testclient.smartschool.co.il':
        this.apiUrl = 'https://testserver.smartschool.co.il/server';
        break;
      case 'webtop.smartschool.co.il':
        this.apiUrl = 'https://webtopserver.smartschool.co.il/server';
        break;
    }
  }

  writeToLog(id, param1 = '', param2 = '', param3 = '', param4 = '', year = -1) {
    if(param1 == 'api/general/SaveStatistics') return;
    this.postData<boolean>('api/general/writeToLog', true,
      {
        id: id,
        param1: param1,
        param2: param2,
        param3: param3,
        param4: param4,
        year: year
      }).pipe(take(1))
      .subscribe((result: baseResult<boolean>) => { });
  }

  postData<T>(url: string, useToken: boolean, postParams: any, useTokenIfExist: boolean = false,
    checkData: boolean = true, rememberMe: boolean = false, retry: boolean = false): Observable<baseResult<T>> {
    const res = new Observable<baseResult<T>>();
    res.pipe(take(1)).subscribe(data => { data.status = false; })

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'language': this.cookieService.getItem('lang') ?? localStorage.getItem('language'),
        'rememberMe': rememberMe ? this.rsa.encryptStringToServer('remeberMe') : '0'
      })
      ,
      withCredentials: true,
      observe: 'response' as 'body'

    };

    return this.http.post<baseResult<T>>(`${this.apiUrl}/${url}`, postParams, httpOptions).pipe(
      map((data: baseResult<T>) => {
        if (!checkData) {
          return;
        }
        if (this.user.getUser() === null) {
          console.log('User is null !!!!!!!!!!!!!!    ' + url);
        }
        if (data.status) {
          this.user.updateLastToken();
          this.cookieService.removeItem('errorCounter');
        } else {
          if (data.errorDescription === 'Not Allow') {// No permissions of pupil card
            window.location.href = window.location.origin;
            return;
          } else if (data.errorDescription.includes('You are not authorized to use this service')) {
            window.location.href = window.location.origin + '/error/notAllowed';
            return;
          }
          this.cookieService.removeItem('errorCounter');
          console.log(url);
        }
        console.log('postData=> ' + url);
        console.log(data);
        return data['body'];
      }));

  }

}
