import { SharedService } from 'src/app/_services/shared.service';

import { TableColumn, TableColumnFooter } from './../tableTypes';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl } from '@angular/forms';
import { AssessmentBankComponent } from '../../assessment-bank/assessment-bank.component';
import { SnackBarComponent } from 'src/app/common/snack-bar/snack-bar.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, AfterViewInit {


  showCtl = false;
  @Input() dataSource: any;
  ds: MatTableDataSource<any>;
  @Input() showCounter = false;
  @Input() isEditMode = false;
  @Input() showFooter = false;
  @Input() showHint = false;
  @Input() noData: string = 'noData2';
  @Input() noDataCss: string = '';
  @Input() sortActive = '';
  @Input() index = '';
  @Input() cbEditEvent = '';
  @Input() sortDirection: SortDirection = 'asc';
  @Input() tableHeight = 350;
  @Input() showEventSecondHeader: any[] = null;
  @Input() columns: TableColumn[] = [];
  @Input() columnsFooter: TableColumnFooter[] = [];
  @Output() checkBoxClicked = new EventEmitter<any>();
  @Output() textChanged = new EventEmitter<any>();
  @Output() radioButtonClicked = new EventEmitter<any>();
  @Output() dynamicClicked = new EventEmitter<any>();
  @Output() rowClick = new EventEmitter<any>();
  @Output() loadComplete = new EventEmitter();
  @Output() externalSort = new EventEmitter<any>();

  isLoading = true;
  roundGrades = true;
  gradesArr = [];
  selectedRows = []
  hover = '';
  hoverIndex = -1;
  @Input() displayedColumns: string[] = [];

  assessmentList: AssessmentBankComponent[] = [];
  componentShowEventSecondHeader: any[];
  @ViewChildren(AssessmentBankComponent) set f1(j: QueryList<AssessmentBankComponent>) {
    if (j !== undefined && j !== null) {
      j.forEach(x => {
        this.assessmentList.push(x);
      })

    }
  };

  private paginator: MatPaginator;
  private sort: MatSort;
  showRoundGrades: boolean;


   @ViewChild(MatSort) set matSort(ms: MatSort) {

    this.sort = ms;
    this.setDataSourceAttributes();
  }

  /*   @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
      this.paginator = mp;
      this.setDataSourceAttributes();
    } */
  updateTableData(dataSource) {
    this.dataSource = dataSource;
    if (this.cbEditEvent != '') {
      this.selectedRows = [];
      this.dataSource.forEach(x => {
        if (x.events[this.cbEditEvent].value) {
          this.selectedRows.push(x[this.index]);
        }
      });
    }
    this.setDataSourceAttributes();
  }

  sortData($event) {
    if (this.externalSort.observers.length > 0) {
      this.externalSort.emit($event);
    }
  }

  setSortActive(sortActive, sortDirection = 'asc') {
    this.sortActive = sortActive;
    if (sortDirection == 'asc')
      this.sortDirection = 'asc';
    else
      this.sortDirection = 'desc';
  }

  updateTableColumn(data) {
    this.columns = data;
  }
  mouseenter(i) {
    this.hoverIndex = i;
    this.hover = 'rowHover';
  }
  mouseleave() {
    this.hoverIndex = -1;
    this.hover = '';
  }

  showCb(i) {
    if (this.hoverIndex == i || this.isEditMode) return 'showCb';
  }

  checkHover(i) {
    if (this.hoverIndex == i) return this.hover;
  }
  getBank(column) {

    const tmp = this.columns.filter(x => x.id == column.id && x.type == column.type);
    if (tmp.length > 0 && tmp[0].bank?.length > 0) {
      this.assessmentList.forEach(assessment => {
        assessment.setItemsBank(tmp[0].bank);
      });
      column.bank = tmp[0].bank;
      return column.bank;
    }
  }

  rowSelected(i, $event) {
    let index = this.dataSource[i][this.index]
    if ($event)
      this.selectedRows.push(index);
    else
      this.selectedRows = this.selectedRows.filter(x => x != index);
    console.log(i)
  }

  selectedRowsExist(i) {
    if (this.dataSource[i] != null) {
      let index = this.dataSource[i][this.index]
      return this.selectedRows.filter(x => x == index).length > 0;
    }
    return 0;
  }

  setDataSourceAttributes() {
    setTimeout(() => {

      this.ds = new MatTableDataSource(this.dataSource);
      this.ds.paginator = this.paginator;
      if (this.externalSort.observers.length == 0) {
        this.ds.sort = this.sort;
        if (this.ds.sort != undefined) {
          this.ds.sort.active = this.sortActive;
          this.ds.sort.direction = this.sortDirection;
        }
      }
      this.isLoading = false;
    }, 10);
  }


  readDataComplete = false;
  constructor(
    public sharedService: SharedService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef,

  ) {

  }

  setShowEventSecondHeader(data, selectType) {
    this.showEventSecondHeader = data;
    if (this.showEventSecondHeader != null && this.showEventSecondHeader.length > 0) {
      for (let index = 0; index <= 100; index++) {
        this.gradesArr.push({ text: `${index}${selectType == 0 ? '%' : ''}`, value: index });
      }
      this.roundGrades = this.showEventSecondHeader.filter(x => x.type == 'roundGrades')[0]?.value == 1;
      this.showRoundGrades = this.showEventSecondHeader.filter(x => x.type == 'roundGrades').length > 0
    }
    this.componentShowEventSecondHeader = this.getShowEventSecondHeader();
  }

  checkNumberData(dataToCheck: any[]) {
    let flag = true;
    (this.dataSource as any[]).forEach(x => {
      dataToCheck.forEach(y => {
        let val = x.events[y.id].value;
        if (!this.sharedService.isEmpty(val)) {
          if (val < y.minValue || val > y.maxValue) {
            flag = false;
          }
        }
      });
    });
    return flag;
  }

  ngOnInit(): void {
    this.showCtl = false;
  }

  loadComplete1() {
    setTimeout(() => {
      this.showCtl = true;
    }, 1);
  }



  gradeHeaderChanged($event, item) {
    if (this.columns.filter(x => x.id == 'comp_' + item.value.id).length > 0) {
      this.columns.filter(x => x.id == 'comp_' + item.value.id)[0].maxValue = $event;
    }
    this.dynamicClicked.emit(
      {
        eventClick: 'selectChanged',
        data: {
          event: $event,
          item: item
        }
      }
    )
  }

  roundGradesClick($event) {
    this.roundGrades = $event;
    this.dynamicClick('roundGrades', $event);
  }

  isSticky(id: string) {
    return this.columns.filter(x => x.id == id)[0]?.isSticky;
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.readDataComplete = true;
    }, 1000);
  }

  headerClicked($event, element, column) {
    this.ds.data.forEach(elem => {
      elem.events[column.id].setValue($event);
      this.eventChecked($event, elem, column);
    });
  }
  dynamicClick(eventClick, data) {
    this.dynamicClicked.emit({
      eventClick: eventClick,
      data: data
    })

  }
  numberTextWithIconBlur(column, value) {
    if (!this.sharedService.isEmpty(column.minValue)) {
      if (value < column.minValue || value > column.maxValue) {

        const text = this.translate.instant('MinMaxGrade').replace('{MinValue}', column.minValue).replace('{MaxValue}', column.maxValue);
        this.snackBar.openFromComponent(SnackBarComponent, {
          duration: 3000,
          data: { message: text, type: 'warning' }
        });
        return '';
      }
    }
    return value;
  }

  eventChecked($event, element, column) {

    this.checkBoxClicked.emit({
      status: $event,
      element: element,
      column: column
    });


  }

  radioButtonCheck($event, element, column) {
    this.radioButtonClicked.emit({
      radioCheck: $event,
      element: element,
      column: column
    });
  }

  getShowEventSecondHeader() {
    return this.showEventSecondHeader.filter(x => x.type == 'component');
  }
  getSpaceSecondHeader(type) {
    return this.showEventSecondHeader.filter(x => x.type == type);
  }
  textboxChanged($event, element, column, ctl: UntypedFormControl = null) {
    if (column.type == 'numberTextWithIcon') {
      $event.value = this.numberTextWithIconBlur(column, $event.value)
      ctl.setValue($event.value);
      (document.getElementsByClassName($event.classList)[0] as any).value = $event.value;
    }
    this.dynamicClicked.emit({
      eventClick: 'textChanged',
      data: {
        text: $event.value ?? $event,
        element: element,
        column: column.id
      }
    });
  }

  setAssesmentData(element, column, $event) {
    if ($event == null) {
      this.getControl(element, column)?.setValue('')
    }
    else {
      this.getControl(element, column)?.setValue(`${$event.val} - ${$event.text}`)
    }
    this.dynamicClicked.emit({
      eventClick: 'assesmentChanged',
      data: {
        text: $event,
        element: element,
        column: column.id
      }
    });
  }


  checkAllClicked(column) {
    const students = this.ds?.data.length;
    if (students === undefined) {
      return false;
    }
    let cnt = 0;
    this.ds.data.forEach(elem => {
      cnt += elem?.events[column.id]?.value;
    });

    return cnt === students;
  }

  getControl(element, column, test = '') {
    // if(test !='') console.log(element)
    if (element['events'] !== undefined) {
      return (element['events'][column.id])
    } else {
      return (element[column.id])
    }
  }

  getControlById(element, column) {
    if (element['events'] !== undefined) {
      return (element['events'][column])
    } else {
      return (element[column])
    }
  }

  getBadgeValue(element, column) {
    if (element['badges'] !== undefined) {
      return (element['badges'][column.id])
    }
  }

  checkData(data: string) {
    const tmp = data.split('**##**');
    return tmp.length !== 1;
  }

  valueInArray(data, separator, pos, defaultValueOnEmpty = '') {
    if (this.sharedService.isEmpty(data)) {
      return defaultValueOnEmpty;
    }
    const tmp = data.toString().split(separator);
    if (tmp.length - 1 < pos) {
      return defaultValueOnEmpty;
    }
    return tmp[pos];
  }

  getData(data: string, pos) {
    const tmp = data.split('**##**');
    return tmp[pos];
  }

  getToolTip(column, element) {
    if (column.toolTip == 'privateToolTip') {
      try {

        return this.sharedService.isEmpty(element.events['tooltip_' + column.id]?.value) ? null : element.events['tooltip_' + column.id].value;
      } catch {
        return 'Err';

      }
    } else {
      return column.toolTip;
    }
  }

  showFooterData(index) {
    return this.showFooter && this.columnsFooter.filter(x => x.columnIndex == index).length > 0;
  }

  getFooterData(index, column) {
    let data = this.columnsFooter.filter(x => x.columnIndex == index)[0];
    switch (data.columnType) {
      case 1:
        return data.text;
      case 3:
        return this.calcAvg(column);
      default:
        return '';
    }
  }

  getFooterInnerHtmlData(index, column) {
    let data = this.columnsFooter.filter(x => x.columnIndex == index)[0];
    return data?.innerHtml ?? '';
  }

  calcAvg(column) {
    if (this.dataSource == undefined || this.dataSource == null) return '';
    if ((this.dataSource as []).length == 0) return '';
    let total = 0;
    let rec = 0;
    (this.dataSource as []).forEach(d => {
      let tmp = (this.getControl(d, column)?.value) ?? this.valueInArray(d[column.id], '**##**', 0)
      if (tmp != null && tmp != '') {
        total += parseFloat(tmp);
        rec++;

      }
    });
    if (rec == 0) return '';
    return Math.round(total / rec * 100) / 100


  }

  changeFocus(x) {
    if (x < 0) {
      x = (this.dataSource as []).length - 1;
    } else if (x >= (this.dataSource as []).length - 1) {
      x = 0
    }
    const elem = document.getElementById('row-' + x);

    elem.focus();
  }



}


