// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `virtual-scroller {

  height: calc(100vh - 431px);
}

table {
  min-width: 100%;
}

table thead th {

  position: -webkit-sticky;

  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--table-background-color);
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  background: var(--table-background-color);
  z-index: 2 !important;
}
.item{
  width: 100%;
  height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/table/list-table/list-table.component.css"],"names":[],"mappings":"AAAA;;EAEE,2BAA2B;AAC7B;;AAEA;EACE,eAAe;AACjB;;AAEA;;EAEE,wBAAgB;;EAAhB,gBAAgB;EAChB,MAAM;EACN,UAAU;EACV,yCAAyC;AAC3C;;AAEA;EACE,wBAAgB;EAAhB,gBAAgB;EAChB,yCAAyC;EACzC,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":["virtual-scroller {\r\n\r\n  height: calc(100vh - 431px);\r\n}\r\n\r\ntable {\r\n  min-width: 100%;\r\n}\r\n\r\ntable thead th {\r\n\r\n  position: sticky;\r\n  top: 0;\r\n  z-index: 1;\r\n  background: var(--table-background-color);\r\n}\r\n\r\n.sticky {\r\n  position: sticky;\r\n  background: var(--table-background-color);\r\n  z-index: 2 !important;\r\n}\r\n.item{\r\n  width: 100%;\r\n  height: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
