import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';
import { TableColumn } from '../tableTypes';

@Component({
  selector: 'app-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.css','../table/table.component.css']
})
export class ListTableComponent implements OnInit {

  @Input() dataSource: any[] = [];
  @Input() stickyWidth: any[] = [];
  @Input() isEditMode = false;
  @Input() columns: TableColumn[] = [];
  hover = '';
  hoverIndex = -1;
  showCtl = false;
  constructor() {

  }
  ngOnInit(): void {
    this.columns.forEach((x,index)=> {
      if(x.isSticky) {
        let px = 0;
        for (let i = 0; i < index; i++) {
          px+=parseInt(this.columns[i].columnWidth.replace('columnWidth',''))
        }
        this.stickyWidth.push(`${px}px`)
      }
    })
  }

  checkHover(i) {
    if (this.hoverIndex == i) return this.hover;
  }

  showCb(i) {
    if (this.hoverIndex == i || this.isEditMode) return 'showCb';
  }
  isSticky(id: string) {
    return this.columns.filter(x => x.id == id)[0]?.isSticky;
  }
  calcRight(i) {

  }

  getControl(element, column, test = '') {
    // if(test !='') console.log(element)
    if (element['events'] !== undefined) {
      return (element['events'][column.id])
    } else {
      return (element[column.id])
    }
  }

  getControlById(element, column) {
    if (element['events'] !== undefined) {
      return (element['events'][column])
    } else {
      return (element[column])
    }
  }

  getBadgeValue(element, column) {
    if (element['badges'] !== undefined) {
      return (element['badges'][column.id])
    }
  }

  @ViewChild('scroll') scroller: VirtualScrollerComponent;

  onVsUpdate(event) {
    console.log('vs update', event);
  }

  onVsChange(event) {
    console.log('vs change', event);
  }

  onVsStart(event) {
    console.log('vs start', event);
  }

  onVsEnd(event) {
    console.log('vs end', event);
  }
}
